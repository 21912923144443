import { Flex } from "@chakra-ui/react";
import { useMainContext } from "../../utils/context";
import { LoginComponent } from "bitrix-authorization";
import { useSelector } from "react-redux";

export const Login = () => {
  const { setIsAuthenticated, setUser } = useMainContext();

  const user = useSelector((state) => state.user);

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="50vh"
    >
      <LoginComponent
        setIsAuthenticated={setIsAuthenticated}
        user={user}
        setUserAction={setUser}
      />
    </Flex>
  );
};
