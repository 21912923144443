import PageHeader from "../../components/page_header/PageHeader";
import { useState, useEffect } from "react";
import "../cards/Cards.sass";
import SortHeader from "../../components/sortHeader/SortHeader.jsx";
import { PredictionsWrapper } from "../../components/wrappers/PredictionsWrapper/PredictionsWrapper.jsx";
import { useDispatch } from "react-redux";
import { getPrediction } from "../../actions/prediction";
import React from "react";
import BX24API from "../../bx24";
import { setLoad } from "../../reducers/loadReducer";

let titles = [
  {
    name: "name",
    value: "Передбачення",
  },
  {
    name: "status",
    value: "Статус",
  },
];

export const Predictions = () => {
  const [search, setSearch] = useState("");
  const [editingPrediction, setEditingPrediction] = useState({});
  const [isModalActive, setIsModalActive] = useState(false);
  const [modalType, setModalType] = useState("edit");
  const [predictions, setPredictions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [statusPrediction, setStatusPrediction] = useState(true);
  const [userData, setUserData] = useState(null);

  const perPage = 50;

  const dispatch = useDispatch();

  useEffect(() => {
    getUserFromBX24().then();
    dispatch(setLoad(false));
  }, []);

  useEffect(() => {
    const checkBX24 = () => {
      try {
        if (typeof window.BX24 !== "undefined") {
          const screenWidth = window.BX24.getWindowSize().width;

          window.BX24.resizeWindow(screenWidth, 1000, () => {
            console.log("Window resized to fit the screen");
          });
        } else {
          console.log("BX24 is not loaded yet, retrying in 100ms");
          setTimeout(checkBX24, 100);
        }
      } catch (error) {
        if (
          error.message.includes("Unable to initialize Bitrix24 JS library")
        ) {
          console.log(
            "Ignoring the error: Unable to initialize Bitrix24 JS library."
          );
        } else {
          console.error("An unexpected error occurred:", error);
        }
      }
    };

    checkBX24();
  }, []);

  const getUserFromBX24 = async () => {
    const result = await BX24API.callMethod("user.current", {});
    setUserData(result.result);
  };

  const handleAddPrediction = () => {
    setModalType("add");
    setIsModalActive(true);
  };

  const getPredictionSendRequest = () => {
    dispatch(
      getPrediction(
        setPredictions,
        setTotalCount,
        statusPrediction,
        search,
        perPage * currentPage
      )
    ).then();
  };

  useEffect(() => {
    getPredictionSendRequest();
  }, [currentPage, statusPrediction]);

  return (
    <div className="page">
      <PageHeader title="Передбачення" />

      <div className="service__header">
        <div className="service-input">
          <input
            placeholder="Пошук"
            type="search"
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                getPredictionSendRequest();
              }
            }}
            onChange={(event) => setSearch(event.target.value)}
            value={search}
          />
        </div>
        <button onClick={handleAddPrediction}>Додати передбачення</button>
      </div>

      <SortHeader
        titles={titles}
        status={statusPrediction}
        setStatus={setStatusPrediction}
      />

      <PredictionsWrapper
        predictions={predictions}
        setPredictions={setPredictions}
        editingPrediction={editingPrediction}
        setEditingPrediction={setEditingPrediction}
        isModalActive={isModalActive}
        setIsModalActive={setIsModalActive}
        modalType={modalType}
        setModalType={setModalType}
        totalCount={totalCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPage={perPage}
        getPredictionSendRequest={getPredictionSendRequest}
        userData={userData}
      />
    </div>
  );
};
