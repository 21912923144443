import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllStatistic } from "../../../actions/cards";
import { Bar, Pie } from "react-chartjs-2";
import "./Statistic.sass";
import { NavLink } from "react-router-dom";
import arrowBack from "../../../assets/icons/arrow-left.png";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  registerables,
} from "chart.js";
import { accesses } from "../../../constants";
import { Unavailable } from "../../../components/Unavailable/Unavailable";
import { getIsAccessed } from "../../../utils/utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
// або якщо вам потрібно зареєструвати все:
ChartJS.register(...registerables);

export const Statistic = () => {
  const [difference, setDifference] = useState({});
  const [statusCount, setStatusCount] = useState({});
  const [monthStatistic, setMonthStatistic] = useState([]);
  const [monthStatisticPerDay, setMonthStatisticPerDay] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [isAccessed, setIsAccessed] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(
    (new Date().getMonth() + 1).toString()
  );

  const user = useSelector((state) => state.user);

  const accessedDepartments = accesses.statistic;

  const formattedNumber = (number) => {
    const result = parseFloat(number).toLocaleString("uk-UA", {
      style: "decimal",
      maximumFractionDigits: 2,
    });
    return result + ` грн.`;
  };

  const monthNames = [
    "Січень",
    "Лютий",
    "Березень",
    "Квітень",
    "Травень",
    "Червень",
    "Липень",
    "Серпень",
    "Вересень",
    "Жовтень",
    "Листопад",
    "Грудень",
  ];

  const years = [2023, 2024, 2025];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAllStatistic(
        setDifference,
        setStatusCount,
        setMonthStatistic,
        setMonthStatisticPerDay,
        selectedYear
      )
    ).then();
  }, [selectedYear]);

  useEffect(() => {
    if (user) {
      const isHasAccess = getIsAccessed(user, accessedDepartments);

      setIsAccessed(isHasAccess);
    }
  }, [user]);

  const getMonthName = (month) => {
    return monthNames[month - 1];
  };

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "short" };
    const date = new Date(dateString);
    return date.toLocaleDateString("uk-UA", options);
  };

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setSelectedMonth(selectedMonth);
  };

  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    setSelectedYear(selectedYear);
  };

  const data = monthStatistic.map((entry) => ({
    month: getMonthName(entry.month),
    total_payment: parseFloat(entry.total_payment),
  }));

  const chartData = {
    labels: data.map((entry) => entry.month),
    datasets: [
      {
        label: `Місячна статистика ${selectedYear} рік`,
        data: data.map((entry) => entry.total_payment),
        backgroundColor: "rgba(75,192,192,1)",
      },
    ],
  };

  // Налаштування діаграми
  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    height: 400,
    responsive: true,
  };

  const filteredData = monthStatisticPerDay.filter(
    (entry) => entry.month === selectedMonth
  );
  const labelsPerDay = filteredData.map((entry) => formatDate(entry.day));
  const dataPerDay = filteredData.map((entry) =>
    parseFloat(entry.total_payment)
  );

  const chartDataPerDay = {
    labels: labelsPerDay,
    datasets: [
      {
        label: "Статистика за день",
        data: dataPerDay,
        backgroundColor: "rgba(75,192,192,1)",
      },
    ],
  };

  const optionsPerDay = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    responsive: true,
    height: 4000,
  };

  const chartDataPie = {
    labels: ["Активні", "Неактивні"],
    datasets: [
      {
        data: [
          parseFloat(statusCount.totalOpenedCards),
          parseFloat(statusCount.totalClosedCards),
        ],
        backgroundColor: ["#92c1e9", "#FF6384"],
        // backgroundColor: ["#36A2EB", "#FF6384"],
      },
    ],
  };

  const remainingDataDay = {
    labels: [
      `Денний залишок ${formattedNumber(difference.dayDifference)}`,
      `Загальна можлива сума ${formattedNumber(difference.dayTotalLimit)}`,
    ],
    datasets: [
      {
        data: [
          Math.abs(parseFloat(difference.dayDifference)),
          Math.abs(parseFloat(difference.dayTotalLimit)),
        ],
        backgroundColor: ["#36A2EB", "#FF6384"],
        // backgroundColor: ["#36A2EB", "#FF6384"],
      },
    ],
  };

  const remainingDataMonth = {
    labels: [
      `Місячний залишок ${formattedNumber(difference.monthDifference)}`,
      `Загальна можлива сума ${formattedNumber(difference.monthTotalLimit)}`,
    ],
    datasets: [
      {
        data: [
          Math.abs(parseFloat(difference.monthDifference)),
          Math.abs(parseFloat(difference.monthTotalLimit)),
        ],
        backgroundColor: ["#36A2EB", "#FF6384"],
        // backgroundColor: ["#36A2EB", "#FF6384"],
      },
    ],
  };

  if (isAccessed) {
    return (
      <div className="statisticPage">
        <NavLink to="/cardsAPP">
          <img
            className="arrow"
            style={{ marginBottom: "10px" }}
            src={arrowBack}
            alt={"img"}
          />
        </NavLink>
        <div className="bars">
          <div>
            <select onChange={handleYearChange} value={selectedYear}>
              {years.map((year, index) => (
                <option key={index} value={year}>
                  {year}
                </option>
              ))}
            </select>
            {selectedYear && filteredData.length > 0 ? (
              <div className="bar">
                <Bar data={chartData} options={options} />
              </div>
            ) : (
              <p>За цей рік немає статистики</p>
            )}
          </div>
          <div>
            <select onChange={handleMonthChange} value={selectedMonth}>
              {monthNames.map((month, index) => (
                <option key={index} value={index + 1}>
                  {month}
                </option>
              ))}
            </select>
            {selectedMonth && filteredData.length > 0 ? (
              <div className="bar">
                <Bar data={chartDataPerDay} options={optionsPerDay} />
              </div>
            ) : (
              <p>За цей місяць немає статистики</p>
            )}
          </div>
        </div>

        <div className="pies">
          <div>
            <h3 className="title">Статистика Активних карт</h3>
            <div className="pie">
              <Pie data={chartDataPie} />
            </div>
          </div>

          <div>
            <h3 className="title">Денний залишок</h3>
            <div className="pie">
              <Pie data={remainingDataDay} />
            </div>
          </div>

          <div>
            <h3 className="title">Місячний залишок</h3>
            <div className="pie">
              <Pie data={remainingDataMonth} />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Unavailable />;
  }
};
