import PageHeader from "../../components/page_header/PageHeader";
import { useState, useEffect } from "react";
import "../cards/Cards.sass";
import SortHeader from "../../components/sortHeader/SortHeader.jsx";
import CardWrapper from "../../components/wrappers/Wrapper.jsx";
import {
  getAccountNP,
  getCounterparties,
  refreshCounterparties,
} from "../../actions/counterparties";
import "../../components/modals/modal.sass";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { setLoad } from "../../reducers/loadReducer";
import { accesses } from "../../constants";
import { Unavailable } from "../../components/Unavailable/Unavailable";
import { getIsAccessed } from "../../utils/utils";

let titles = [
  {
    name: "name",
    value: "Контрагент",
  },
  {
    name: "quaterlycirculation",
    value: "Квартальний обіг",
  },
  {
    name: "status",
    value: "Статус",
  },
  {
    name: "poa",
    value: "Довіреність",
  },
  {
    name: "fop",
    value: "ФОП?",
  },
];

export const NovaPoshta = () => {
  const [search, setSearch] = useState("");
  const [counterparties, setCounterparties] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterBy, setFilterBy] = useState("Всі");
  const [filterByAccount, setFilterByAccount] = useState(880341308);
  const [accounts, setAccounts] = useState([]);
  const [isAccessed, setIsAccessed] = useState(false);
  const [confirmRefreshModalIsActive, setConfirmRefreshModalIsActive] =
    useState(false);
  const [activeSort, setActiveSort] = useState({
    sort: titles[0].name,
    desc: false,
  });

  const perPage = 50;
  const accessedDepartments = accesses.novaPoshta;

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user) {
      dispatch(setLoad(false));

      const isHasAccess = getIsAccessed(user, accessedDepartments);

      setIsAccessed(isHasAccess);
    }
  }, [user]);

  const getCounterpartiesSendRequest = () => {
    dispatch(
      getCounterparties(
        setCounterparties,
        setTotalCount,
        perPage * currentPage,
        search,
        filterBy,
        filterByAccount
      )
    ).then();
  };

  const getAccounts = () => {
    dispatch(getAccountNP(setAccounts)).then();
  };

  useEffect(() => {
    getCounterpartiesSendRequest();
    getAccounts();
  }, [currentPage, filterBy, filterByAccount]);

  const handleRefreshCounterparties = () => {
    setConfirmRefreshModalIsActive(false);
    dispatch(refreshCounterparties(getCounterpartiesSendRequest)).then();
  };

  if (isAccessed) {
    return (
      <div className="page">
        {confirmRefreshModalIsActive && (
          <div
            className="back"
            onClick={() => setConfirmRefreshModalIsActive(false)}
          >
            <div className="modal">
              <div className="modal__header">
                <h3>Оновити дані?</h3>
                <button
                  className="close"
                  onClick={setConfirmRefreshModalIsActive}
                >
                  <svg width="20px" height="20px" viewBox="0 0 24 24">
                    <path
                      d="M18 7.70857L16.7914 6.5L12 11.2914L7.20857 6.5L6 7.70857L10.7914 12.5L6 17.2914L7.20857 18.5L12 13.7086L16.7914 18.5L18 17.2914L13.2086 12.5L18 7.70857Z"
                      fill="black"
                    />
                  </svg>
                </button>
              </div>
              <div className="btns">
                <button className="btn" onClick={handleRefreshCounterparties}>
                  Так
                </button>
                <button className="btn">Скасувати</button>
              </div>
            </div>
          </div>
        )}

        <PageHeader title="Контрагенти" />

        <div className="service__header">
          <div className="service-input">
            <input
              placeholder="Пошук"
              type="search"
              onKeyDown={(e) => {
                if (e.keyCode == 13) {
                  setCurrentPage(0);
                  getCounterpartiesSendRequest();
                }
              }}
              onChange={(event) => setSearch(event.target.value)}
              value={search}
            />
          </div>
          <div className="select_wrapper">
            <p>Статус: </p>
            <select
              value={filterBy}
              onChange={(e) => setFilterBy(e.target.value)}
            >
              <option value="Всі">Всі</option>
              <option value="В черзі">В черзі</option>
              <option value="В роботі">В роботі</option>
              <option value="Використаний">Використаний</option>
              <option value="Новий">Новий</option>
              <option value="Використаний для ручного">
                Використаний для ручного
              </option>
              <option value="Черга для ручного">Черга для ручного</option>
            </select>
          </div>
          <div className="select_wrapper">
            <p>Бізнес-кабінет: </p>
            <select
              value={filterByAccount}
              onChange={(e) => setFilterByAccount(e.target.value)}
            >
              {accounts?.map((account) => (
                <option key={account?.accountid} value={account?.accountid}>
                  {account?.name}
                </option>
              ))}
            </select>
          </div>
          <button
            className="download_csv"
            onClick={() => setConfirmRefreshModalIsActive(true)}
          >
            Оновити список
          </button>
        </div>

        <SortHeader
          titles={titles}
          setActiveSort={setActiveSort}
          activeSort={activeSort}
          setPage={setCurrentPage}
        />

        <CardWrapper
          data={counterparties}
          titles={titles}
          search={search}
          perPage={perPage}
          totalCount={totalCount}
          getRequest={getCounterpartiesSendRequest}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          dataType="counterparties"
          setCounterparties={setCounterparties}
        />
      </div>
    );
  } else {
    return <Unavailable />;
  }
};
