const SET_LOAD = "SET_LOAD";

const defaultState = true;

export default function loadReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_LOAD:
      return action.payload;
    default:
      return state;
  }
}

export const setLoad = (isLoad) => ({ type: SET_LOAD, payload: isLoad });
