import { setLoad } from "../reducers/loadReducer";
import axiosInstance from "../axios/axios";


export const getPrediction = (
  setPrediction,
  setTotalCount,
  status,
  search = "",
  offset = 0
) => {
  return async (dispatch) => {
    try {
      dispatch(setLoad(true));
      axiosInstance
        .get(
          `/predictions?status=${status}&offset=${offset}&search=${search}`
        )
        .then(function (res) {
          setPrediction(res.data.data);
          setTotalCount(res.data.totalCount);
          dispatch(setLoad(false));
        })
        .catch(function (error) {
          console.log(error);
          dispatch(setLoad(false));
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const createPrediction = (prediction) => {
  return async (dispatch) => {
    try {
      dispatch(setLoad(true));
      axiosInstance
        .post("/predictions/create", { prediction })
        .then(dispatch(setLoad(false)));
    } catch (e) {
      console.error(e);
    }
  };
};

export const deletePrediction = (id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoad(true));

      axiosInstance
        .delete(`/predictions/${id}`)
        .then(dispatch(setLoad(false)));
    } catch (e) {
      console.error(e);
    }
  };
};

export const updatePrediction = (id, prediction, status) => {
  return async (dispatch) => {
    try {
      dispatch(setLoad(true));

      axiosInstance
        .patch(`/predictions/${id}`, {
          prediction,
          status,
        })
        .then(dispatch(setLoad(false)));
    } catch (e) {
      console.error(e);
    }
  };
};
