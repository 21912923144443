import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { accesses } from "../../constants";
import { useEffect, useState } from "react";
import { getIsAccessed } from "../../utils/utils";
import { Unavailable } from "../../components/Unavailable/Unavailable";
import { centerBlockStyle, homePageStyle } from "../../styles/styles";
import { Button } from "@chakra-ui/react";

export const HomePage = () => {
  const [isNovaPoshtaAccessed, setIsNovaPoshtaAccessed] = useState(false);
  const [isCardsAccessed, setIsCardsAccessed] = useState(false);
  const [isPredictionsAccessed, setIsPredictionsAccessed] = useState(false);

  const user = useSelector((state) => state.user);

  const novaPoshtaAccessedDepartments = accesses.novaPoshta;
  const cardsAccessedDepartments = accesses.cards;
  const predictionsAccessedDepartments = accesses.predictions;

  useEffect(() => {
    if (user) {
      const novaPoshtaAccess = getIsAccessed(
        user,
        novaPoshtaAccessedDepartments
      );

      const cardAccess = getIsAccessed(user, cardsAccessedDepartments);
      const predictionsAccess = getIsAccessed(
        user,
        predictionsAccessedDepartments
      );

      setIsPredictionsAccessed(predictionsAccess);
      setIsCardsAccessed(cardAccess);
      setIsNovaPoshtaAccessed(novaPoshtaAccess);
    }
  }, [user]);

  return (
    <div style={centerBlockStyle}>
      <div style={homePageStyle}>
        {isNovaPoshtaAccessed && (
          <Link to="/novaposhtaapp">
            <Button colorScheme="yellow">Нова пошта</Button>
          </Link>
        )}

        {isCardsAccessed && (
          <Link to="/cardsapp">
            <Button colorScheme="yellow">Карточки</Button>
          </Link>
        )}

        {isPredictionsAccessed && (
          <Link to="/predictionsapp">
            <Button colorScheme="yellow">Передбачення</Button>
          </Link>
        )}

        {!isCardsAccessed && !isNovaPoshtaAccessed && <Unavailable />}
      </div>
    </div>
  );
};
