import React from "react";
import "./Input.sass";
import InfoIcon from "../../assets/icons/edit.svg";



const Input = (props) => {
  const [lengthError, setLengthError] = React.useState(false);
  const maxLength = 300;

  const changeValueHandler = (event) => {
    if (props.name === "phoneNumber") {
      if (event.target.value.slice(0, 4) !== "+380") {
        props.setValue("+380");
      } else {
        props.setValue(event.target.value);
      }
    } else {
      props.setValue(event.target.value);
    }
  };

  const handlerCheckLength = (event) => {
    if (
      event.target.value.length === props.maxLength ||
      event.target.value.length === maxLength
    ) {
      setLengthError(true);
    } else {
      setLengthError(false);
    }
  };

  return (
    <div className="input__block">
      <label className="input">
        <input
          onChange={(event) => {
            changeValueHandler(event);
            handlerCheckLength(event);
          }}
          value={props.value}
          type={props.type}
          name={props.name}
          onKeyDown={props.onKeyDown}
          placeholder={props.placeholder}
          className={
             props.error ? "input__field input_error" : "input__field"
          }
          maxLength={props.maxLength || maxLength}
        />
      </label>


      {props.error && (
        <div id="error" className="error">
          <img src={InfoIcon} alt="" className="error_icon" />
          <p className="form_item_error">{props.error}</p>
        </div>
      )}

      {lengthError && (
        <div id="error" className="error">
          <img src={InfoIcon} alt="" className="error_icon" />
          <p className="form_item_error">
            Ви написали максимальну кількість символів
          </p>
        </div>
      )}
    </div>
  );
};

export default Input;
