import { useEffect, useState } from 'react';
import { getTotal } from '../../actions/cards';
import { useDispatch } from "react-redux";
import React from "react";


const TotalSum = () => {
    const [dayTotal, setDayTotal] = useState(0);
    const [monthTotal, setMonthTotal] = useState(0);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTotal(setDayTotal, setMonthTotal));
    }, [])


    return (
        <div className="total">
            <p>
                Денний обіг:
                <span>
                  {" "}
                  {parseFloat(dayTotal).toLocaleString('uk-UA', {style: 'decimal',  maximumFractionDigits: 2})}
                  {" "}
                </span>
              грн.
            </p>
            <p>
                Місячний обіг:
                <span>
                    {" "}
                  {parseFloat(monthTotal).toLocaleString('uk-UA', {style: 'decimal',  maximumFractionDigits: 2})}
                  {" "}
                </span>
              грн.
            </p>
        </div>
    );
}

export default TotalSum;