import PageHeader from "../../components/page_header/PageHeader";
import "./Cards.sass";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SortHeader from "../../components/sortHeader/SortHeader.jsx";
import CardWrapper from "../../components/wrappers/Wrapper.jsx";
import { getCards } from "../../actions/cards";
import TotalSum from "../../components/totalSum/TotalSum";
import React from "react";
import BX24API from "../../bx24";
import { setLoad } from "../../reducers/loadReducer";
import { accesses } from "../../constants";
import { getUserFromBX24 } from "../../utils/getUserFromBx24";
import { Unavailable } from "../../components/Unavailable/Unavailable";
import { getIsAccessed } from "../../utils/utils";

export const Cards = () => {
  const [search, setSearch] = useState("");
  const [cards, setCards] = useState([]);
  const [titles, setTitles] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [activeSort, setActiveSort] = useState({});
  const [isClosed, setIsClosed] = useState("Всі");
  const [isActive, setIsActive] = useState("Всі");
  const [forAgeCheck, setForAgeCheck] = useState("Всі");
  const [type, setType] = useState("Всі");
  const [organisation, setOrganisation] = useState("Всі");
  const [isAccessed, setIsAccessed] = useState(false);

  const windowWidth = 1200;
  const perPage = 50;
  const accessedDepartments = accesses.cards;

  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      const isHasAccess = getIsAccessed(user, accessedDepartments);

      setIsAccessed(isHasAccess);
    }
  }, [user]);

  useEffect(() => {
    windowWidth > 900
      ? setTitles([
          {
            name: "name",
            value: "Назва",
          },
          {
            name: "daycirculation",
            value: "Дений обіг",
          },
          {
            name: "monthcirculation",
            value: "Місячний обіг",
          },
          {
            name: "daycirculationlimit",
            value: "Дений ліміт",
          },
          {
            name: "monthcirculationlimit",
            value: "Місячний ліміт",
          },
          {
            name: "isactive",
            value: "Статус",
          },
        ])
      : windowWidth > 500
      ? setTitles([
          {
            name: "name",
            value: "Назва",
          },
          {
            name: "daycirculation",
            value: "Дений",
          },
          {
            name: "monthcirculation",
            value: "Місячний",
          },
          {
            name: "isactive",
            value: "Статус",
          },
        ])
      : setTitles([
          {
            name: "name",
            value: "Назва",
          },
          {
            name: "isactive",
            value: "Статус",
          },
        ]);

    return () =>
      setActiveSort({
        sort: titles[0]?.name,
        desc: false,
      });
  }, []);

  function getCardsSendRequest() {
    dispatch(
      getCards(
        setCards,
        setTotalCount,
        perPage * currentPage,
        activeSort.desc ? "-" + activeSort.sort : activeSort.sort,
        search,
        isClosed,
        isActive,
        type,
        organisation,
        forAgeCheck
      )
    ).then();
  }

  const setIsClosedValue = (value) => {
    setIsClosed(value);
  };
  const setIsActiveValue = (value) => {
    setIsActive(value);
  };

  const setForAgeCheckValue = (value) => {
    setForAgeCheck(value);
  };

  useEffect(() => {
    getCardsSendRequest();
  }, [
    activeSort,
    currentPage,
    isClosed,
    isActive,
    type,
    organisation,
    forAgeCheck,
  ]);

  if (isAccessed) {
    return (
      <div className="page service">
        <PageHeader title="Карточки" cards={cards} setCards={setCards} />

        <div className="service__header">
          <div className="service-input">
            <input
              placeholder="Пошук"
              type="search"
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  setCurrentPage(0);
                  getCardsSendRequest();
                }
              }}
              onChange={(event) => setSearch(event.target.value)}
              value={search}
            />
          </div>
          <TotalSum />
        </div>

        <div className="service__filters">
          <div className="service-filter">
            <label>
              <span>Закрита/Відкрита:</span>
              <select
                onChange={(event) => setIsClosedValue(event.target.value)}
                className="service-filter-select"
              >
                <option value={"Всі"}>Всі</option>
                <option value={"true"}>Закриті</option>
                <option value={"false"}>Відкриті</option>
              </select>
            </label>
          </div>

          <div className="service-filter">
            <label>
              <span>Активна/Неактивна:</span>
              <select
                onChange={(event) => setIsActiveValue(event.target.value)}
                className="service-filter-select"
              >
                <option value={"Всі"}>Всі</option>
                <option value={"true"}>Активна</option>
                <option value={"false"}>Неактивна</option>
              </select>
            </label>
          </div>

          <div className="service-filter">
            <label>
              <span>Тип:</span>
              <select
                onChange={(event) => setType(event.target.value)}
                className="service-filter-select"
              >
                <option value={"Всі"}>Всі</option>
                <option value={"Картка"}>Картка</option>
                <option value={"ФОП"}>ФОП</option>
              </select>
            </label>
          </div>

          <div className="service-filter">
            <label>
              <span>Для перевірки:</span>

              <select
                onChange={(event) => setForAgeCheckValue(event.target.value)}
                className="service-filter-select"
              >
                <option value={"Всі"}>Всі</option>
                <option value={"true"}>Так</option>
                <option value={"false"}>Ні</option>
              </select>
            </label>
          </div>

          <div className="service-filter">
            <label>
              <span>Організація:</span>
              <select
                onChange={(event) => setOrganisation(event.target.value)}
                className="service-filter-select"
              >
                <option value={"Всі"}>Всі</option>
                <option value={"VandalVape"}>Vandal Vape</option>
                <option value={"VapeHub"}>Vape Hub</option>
              </select>
            </label>
          </div>
        </div>

        <SortHeader
          titles={titles}
          setActiveSort={setActiveSort}
          activeSort={activeSort}
        />

        <CardWrapper
          data={cards}
          titles={titles}
          setCards={setCards}
          search={search}
          perPage={perPage}
          totalCount={totalCount}
          getRequest={getCardsSendRequest}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    );
  } else {
    return <Unavailable />;
  }
};
