import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { store } from "./reducers";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import "./styles/index.sass";

ReactDOM.createRoot(document.getElementById("app")).render(
  <Provider store={store}>
    <BrowserRouter>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </BrowserRouter>
  </Provider>
);
