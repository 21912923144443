import { setLoad } from "../reducers/loadReducer";
import axiosInstance from "../axios/axios";

export const getCards = (
  setCards,
  setTotalCount,
  offset = 0,
  activeSort = "name",
  search = "",
  isClosed,
  isActive,
  type,
  organisation,
  forAgeCheck
) => {
  return async (dispatch) => {
    try {
      axiosInstance
        .get(
          `/cards?search=${search}&offset=${offset}&sortBy=${activeSort}&isClosed=${isClosed}&isActive=${isActive}&cardType=${type}&organisation=${organisation}&foragecheck=${forAgeCheck}`
        )
        .then(function (res) {
          setCards(res.data.data);
          setTotalCount(res.data.totalCount);
          dispatch(setLoad(false));
        })
        .catch(function (error) {
          console.log(error);
          dispatch(setLoad(false));
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getTotal = (setTotalDaySum, setTotalMonthSum) => {
  return async (dispatch) => {
    try {
      dispatch(setLoad(true));
      axiosInstance
        .post("/cards/getTotal")
        .then((res) => {
          setTotalDaySum(res.data.daycirculation);
          setTotalMonthSum(res.data.monthcirculation);
        })
        .catch(function (error) {
          console.log(error);
        });
      dispatch(setLoad(false));
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateCard = (
  id,
  name,
  cardDetail,
  dayCirculationLimit,
  monthCirculationLimit,
  isActive,
  isClosed,
  bank,
  setModalIsActive = () => {},
  setEditingCard = () => {},
  cards,
  setCards,
  NFNF,
  Organisation,
  forAgeCheck
) => {
  return async (dispatch) => {
    try {
      const updatedCards = cards.map((card) => {
        if (card.id === id) {
          return {
            ...card,
            name: name,
            carddetail: cardDetail,
            daycirculationlimit: dayCirculationLimit,
            monthcirculationlimi: monthCirculationLimit,
            isactive: isActive,
            isclosed: isClosed,
            bank: bank,
            nfnf: NFNF,
            organisation: Organisation,
            foragecheck: forAgeCheck,
          };
        }
        return card;
      });

      await axiosInstance
        .patch("/cards", {
          id,
          name,
          cardDetail,
          dayCirculationLimit,
          monthCirculationLimit,
          isActive,
          isClosed,
          bank,
          NFNF,
          Organisation,
          forAgeCheck,
        })
        .catch((error) => {
          console.log(error);
        });
      setCards(updatedCards);
      setEditingCard({});
      setModalIsActive(false);
    } catch (e) {
      console.log(e);
    }
  };
};

export const addCard = (
  name,
  cardDetail,
  dayCirculationLimit,
  monthCirculationLimit,
  isActive,
  isClosed,
  bank,
  setModalIsActive,
  cards,
  setCards,
  NFNF,
  Organisation,
  forAgeCheck
) => {
  return async (dispatch) => {
    try {
      axiosInstance
        .post("/cards", {
          name,
          cardDetail,
          dayCirculationLimit,
          monthCirculationLimit,
          isActive,
          isClosed,
          bank,
          NFNF,
          Organisation,
          forAgeCheck,
        })
        .then((res) => {
          const newCard = res.data;
          const newCards = [...cards, newCard];
          setCards(newCards);
          setModalIsActive(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const deleteCard = (id, setModalIsActive) => {
  return async (dispatch) => {
    try {
      axiosInstance.delete(`/cards/${id}`).then(() => setModalIsActive(false));
    } catch (error) {
      console.log(error);
    }
  };
};

export const setIsClosed = (cards, setCards, id) => {
  return async (dispatch) => {
    try {
      const updatedCards = cards.map((card) => {
        if (card.id === id) {
          return { ...card, isclosed: !card.isclosed };
        }
        return card;
      });
      axiosInstance
        .patch(`/cards/setIsClosed/${id}`)
        .then(() => {
          setCards(updatedCards);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      dispatch(setLoad(false));
    }
  };
};

export const getAllStatistic = (
  setDifference,
  setStatusCount,
  setMonthStatistic,
  setMonthStatisticPerDay,
  selectedYear
) => {
  return async (dispatch) => {
    try {
      dispatch(setLoad(true));
      const [difference, statusCount, monthStatistic, monthStatisticPerDay] =
        await Promise.all([
          axiosInstance.get(`/cards/getDifference`),
          axiosInstance.get(`/cards/getStatusCount`),
          axiosInstance.get(`/cards/getMonthStatistic?year=${selectedYear}`),
          axiosInstance.get(
            `/cards/getMonthStatisticPerDay?year=${selectedYear}`
          ),
        ]);

      setDifference(difference.data);
      setStatusCount(statusCount.data);
      setMonthStatistic(monthStatistic.data.monthStatistic);
      setMonthStatisticPerDay(monthStatisticPerDay.data.monthStatisticPerDay);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoad(false));
    }
  };
};
