import "./modal.sass";
import { useDispatch } from "react-redux";
import { useState } from "react";
import Button from "../../components/button/button.jsx";
import {
  createPrediction, updatePrediction,
} from "../../actions/prediction.js";
import { DeleteItemConfirm } from "./DeleteItemConfirm";

import React from "react";

export const AddEditPredictionModal = ({
  setModalIsActive,
  prediction,
  predictions,
  setPredictions,
  setEditingPrediction,
  type,
  getPredictionSendRequest,
}) => {
  const [status, setStatus] = useState(
    type === "edit" ? prediction.status : false
  );
  const [predictionName, setPredictionName] = useState(
    type === "edit" ? prediction.prediction : ""
  );
  const [deleteModalIsActive, setDeleteModalIsActive] = useState(false);

  const dispatch = useDispatch();

  const handleAddPrediction = (prediction) => {
    setModalIsActive(false);
    dispatch(createPrediction(prediction)).then(() => {
      getPredictionSendRequest();
    });
  };

  const handleUpdatePrediction = () => {
    setModalIsActive(false);
    dispatch(updatePrediction(prediction.id, predictionName, status)).then(
      () => {
        getPredictionSendRequest();
      }
    );
  };

  const closeModal = () => {
    setModalIsActive(false);
    setEditingPrediction({});
  };

  const onBackgroundClick = (e) => {
    if (e.target.className === "back") {
      closeModal();
    }
  };

  return (
    <>
      <div className="back" onClick={(e) => onBackgroundClick(e)}>
        <div className="modal">
          <div className="modal__header">
            <h3>
              {type === "edit"
                ? "Редагування передбачення"
                : "Створення передбачення"}
            </h3>
            <button className="close" onClick={() => closeModal()}>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 7.70857L16.7914 6.5L12 11.2914L7.20857 6.5L6 7.70857L10.7914 12.5L6 17.2914L7.20857 18.5L12 13.7086L16.7914 18.5L18 17.2914L13.2086 12.5L18 7.70857Z"
                  fill="black"
                />
              </svg>
            </button>
          </div>
          <div className="input_wrapper">
            <p>Передбачення: </p>
            <textarea
              value={predictionName}
              onChange={(e) => setPredictionName(e.target.value)}
            />
          </div>

          {type === "edit" && (
            <div
              className={
                status ? "checkbox-wrapper checked" : "checkbox-wrapper"
              }
            >
              <button
                onClick={() => setStatus(!status)}
                className="switch"
                htmlFor="checkbox"
              >
                <div className="slider round"></div>
              </button>
              <div className="input__name">
                {status ? "В роботі" : "На модерації"}
              </div>
            </div>
          )}
          <div className="button">
            <Button
              text={type === "add" ? "Додати" : "Зберегти"}
              handler={() => {
                type === "add"
                  ? handleAddPrediction(predictionName)
                  : handleUpdatePrediction();
              }}
            />
          </div>
          {type === "edit" && (
            <button
              onClick={() => setDeleteModalIsActive(true)}
              className="delete"
            >
              Видалити передбачення
            </button>
          )}
        </div>
      </div>
      {deleteModalIsActive && (
        <DeleteItemConfirm
          setIsActive={setDeleteModalIsActive}
          data={predictions}
          setData={setPredictions}
          dataType="predictions"
          setParentModalIsActive={setModalIsActive}
          id={prediction.id}
        />
      )}
    </>
  );
};
