import { setLoad } from "../reducers/loadReducer";
import axiosInstance from "../axios/axios";

export const getCounterparties = (
  setCounterparties,
  setTotalCount,
  offset = 0,
  search = "",
  filterBy = "all",
  filterByAccount
) => {
  return async (dispatch) => {
    try {
      dispatch(setLoad(true));
      axiosInstance
        .get(
          `/counterparties?search=${search}&offset=${offset}&filterBy=${filterBy}&filterByAccount=${filterByAccount}`
        )
        .then(function (res) {
          setCounterparties(res.data.data);
          setTotalCount(res.data.totalCount);
          dispatch(setLoad(false));
        })
        .catch(function (error) {
          console.log(error);
          dispatch(setLoad(false));
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const refreshCounterparties = (getCounterpartiesSendRequest) => {
  return async (dispatch) => {
    try {
      dispatch(setLoad(true));
      await axiosInstance.patch("/counterparties/updateList").then(() => {
        getCounterpartiesSendRequest();
        dispatch(setLoad(false));
      });
    } catch (error) {
      console.error(error);
      dispatch(setLoad(false));
    }
  };
};
export const getAccountNP = (setAccounts) => {
  return async (dispatch) => {
    try {
      await axiosInstance.get("/counterparties/getAccounts").then((res) => {
        setAccounts(res.data);
      });
    } catch (error) {
      console.error(error);
      dispatch(setLoad(false));
    }
  };
};

export const resetQuarterlyCirculation = (
  id,
  setCounterparties,
  counterparties
) => {
  return async () => {
    try {
      const response = await axiosInstance.patch(`/counterparties/reset/${id}`);
      if (response.data.card) {
        const updatedCounterparties = counterparties.map((item) =>
          item.id === id ? { ...item, quaterlycirculation: 0 } : item
        );
        setCounterparties(updatedCounterparties);
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const deleteCounterparty = (
  id,
  setModalIsActive,
  setCounterparties,
  counterparties
) => {
  return async (dispatch) => {
    try {
      axiosInstance.delete(`/counterparties/${id}`).then(() => {
        const index = counterparties.findIndex((obj) => {
          return obj.id === id;
        });
        if (index !== -1) {
          counterparties.splice(index, 1);
        }
        setCounterparties(counterparties);
        setModalIsActive(false);
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateCounterparty = (
  id,
  status,
  poa,
  setModalIsActive,
  setCounterparties,
  counterparties,
  account,
  fop
) => {
  return async (dispatch) => {
    try {
      axiosInstance
        .patch(`/counterparties/${id}?account=${account}`, {
          status,
          poa,
          fop,
        })
        .then((res) => {
          let index = counterparties.findIndex((obj) => {
            return obj.id === id;
          });

          if (index !== -1) {
            counterparties[index].status = res.data.updatedCounterparty.status;
            counterparties[index].poa = res.data.updatedCounterparty.poa;
            counterparties[index].fop = res.data.updatedCounterparty.fop;
          }

          if (res.data.updatedCounterpartyInWork) {
            let index2 = counterparties.findIndex((obj) => {
              return obj.id === res.data.updatedCounterpartyInWork.id;
            });

            if (index2 !== -1) {
              counterparties[index2].status =
                res.data.updatedCounterpartyInWork.status;
            }
          }
          setCounterparties(counterparties);
          setModalIsActive(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };
};
