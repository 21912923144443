import ReactPaginate from "react-paginate";
import React from "react";
import '../styles/Users.sass'

export const Pagination = ({
  totalCount,
  currentPage,
  setCurrentPage,
  perPage,
}) => {
  return (
    <>
      {totalCount >= perPage && (
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          pageCount={Math.ceil(totalCount / perPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(e) => setCurrentPage(e.selected)}
          containerClassName={"pagination"}
          activeClassName={"active"}
          forcePage={currentPage}
        />
      )}
    </>
  );
};
