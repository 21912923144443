import "./modal.sass";
import Input from "../../components/input/Input.jsx";
import Button from "../../components/button/button.jsx";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateCard, addCard } from "../../actions/cards.js";
import { DeleteItemConfirm } from "./DeleteItemConfirm";
import React from "react";

export const AddEditCardModal = ({
  setModalIsActive,
  card,
  cards,
  setCards,
  setEditingCard,
  getRequest,
  type,
}) => {
  const {
    name,
    daycirculationlimit,
    monthcirculationlimit,
    carddetail,
    bank,
    isclosed,
    isactive,
    id,
    nfnf,
    organisation,
    foragecheck,
  } = card;
  const dispatch = useDispatch();

  const [cardName, setCardName] = useState(type === "edit" ? name : "");
  const [dayCirculationLimit, setDayCirculationLimit] = useState(
    type === "edit" ? daycirculationlimit : ""
  );
  const [monthCirculationLimit, setMonthCirculationLimit] = useState(
    type === "edit" ? monthcirculationlimit : ""
  );
  const [cardDetail, setCardDetail] = useState(
    type === "edit" ? carddetail : ""
  );
  const [cardBank, setCardBank] = useState(type === "edit" ? bank : "monobank");
  const [Organisation, setOrganisation] = useState(
    type === "edit" ? organisation : "Всі"
  );
  const [isClosed, setIsClosed] = useState(type === "edit" ? isclosed : false);
  const [isActive, setIsActive] = useState(type === "edit" ? isactive : true);
  const [forAgeCheck, setForAgeCheck] = useState(
    type === "edit" ? foragecheck : false
  );
  const [NFNF, setNFNF] = useState(type === "edit" ? nfnf : "");
  const [error, setError] = useState("");
  const [deleteModalIsActive, setDeleteModalIsActive] = useState(false);

  const saveEdits = () => {
    if (
      cardName === "" ||
      dayCirculationLimit === "" ||
      monthCirculationLimit === "" ||
      cardDetail === "" ||
      cardBank === "" ||
      NFNF === ""
    ) {
      setError("Заповніть всі поля!");
    } else if (!Number.isInteger(+dayCirculationLimit)) {
      setError("Некоректно вказаний денний ліміт!");
    } else if (!Number.isInteger(+monthCirculationLimit)) {
      setError("Некоректно вказаний місячний ліміт!");
    } else {
      type === "add"
        ? dispatch(
            addCard(
              cardName,
              cardDetail,
              dayCirculationLimit,
              monthCirculationLimit,
              isActive,
              isClosed,
              cardBank,
              setModalIsActive,
              cards,
              setCards,
              NFNF,
              Organisation,
              forAgeCheck
            )
          ).then() //location.reload()
        : dispatch(
            updateCard(
              id,
              cardName,
              cardDetail,
              dayCirculationLimit,
              monthCirculationLimit,
              isActive,
              isClosed,
              cardBank,
              setModalIsActive,
              setEditingCard,
              cards,
              setCards,
              NFNF,
              Organisation,
              forAgeCheck
            )
          ).then(() => {
            getRequest();
          });
    }
  };

  return (
    <>
      <div className="back edit_back">
        <div className="modal">
          <div className="modal__header">
            <h3>{type === "edit" ? "Редагування карти" : "Створити карту"}</h3>
            <button className="close" onClick={() => setModalIsActive(false)}>
              <svg
                width="25"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 7.70857L16.7914 6.5L12 11.2914L7.20857 6.5L6 7.70857L10.7914 12.5L6 17.2914L7.20857 18.5L12 13.7086L16.7914 18.5L18 17.2914L13.2086 12.5L18 7.70857Z"
                  fill="black"
                />
              </svg>
            </button>
          </div>
          <div className="input_wrapper">
            <p>Назва карти</p>
            <Input type="text" value={cardName} setValue={setCardName} />
          </div>

          <div className="input_wrapper">
            <p>Денний ліміт</p>
            <Input
              type="text"
              value={dayCirculationLimit}
              setValue={setDayCirculationLimit}
            />
          </div>

          <div className="input_wrapper">
            <p>Місячний ліміт</p>
            <Input
              type="text"
              value={monthCirculationLimit}
              setValue={setMonthCirculationLimit}
            />
          </div>

          <div className="input_wrapper">
            <p>Реквізити</p>
            <Input type="text" value={cardDetail} setValue={setCardDetail} />
          </div>

          <div className="input_wrapper">
            <p>НФНФ</p>
            <Input type="text" value={NFNF} setValue={setNFNF} />
          </div>

          <div className="input_wrapper">
            <p>Організація</p>
            <select
              className="select"
              value={Organisation}
              onChange={(e) => setOrganisation(e.target.value)}
            >
              <option value="All">Всі</option>
              <option value="VandalVape">Тільки Vandal Vape</option>
              <option value="VapeHub">Тільки Vape Hub</option>
            </select>
          </div>

          <div className="input_wrapper">
            <p>Банк</p>
            <select name="" id="" className="select">
              <option value={cardBank} onSelect={setCardBank}>
                Монобанк
              </option>
            </select>
          </div>
          <div className="block__row">
            <div
              className={
                isActive ? "checkbox-wrapper checked" : "checkbox-wrapper"
              }
            >
              <button
                onClick={() => setIsActive(!isActive)}
                className="switch"
                htmlFor="checkbox"
              >
                <div className="slider round"></div>
              </button>
              <div className="input__name">
                {isActive ? "Активна" : "Неактивна"}
              </div>
            </div>
          </div>

          <div className="block__row">
            <div
              className={
                isClosed ? "checkbox-wrapper checked" : "checkbox-wrapper"
              }
            >
              <button
                onClick={() => setIsClosed(!isClosed)}
                className="switch"
                htmlFor="checkbox"
              >
                <div className="slider round"></div>
              </button>
              <div className="input__name">
                {isClosed ? "Закрита" : "Відкрита"}
              </div>
            </div>
          </div>

          <div className="block__row">
            <div
              className={
                forAgeCheck ? "checkbox-wrapper checked" : "checkbox-wrapper"
              }
            >
              <button
                onClick={() => setForAgeCheck(!forAgeCheck)}
                className="switch"
                htmlFor="checkbox"
              >
                <div className="slider round"></div>
              </button>
              <div className="input__name">
                {forAgeCheck
                  ? "Для перевірки повноліття"
                  : "Не для перевірки повноліття"}
              </div>
            </div>
          </div>

          {error && <p>{error}</p>}

          <div className="button" onClick={saveEdits}>
            <Button text="Зберегти" />
          </div>
          {type === "edit" && (
            <button
              className="delete"
              onClick={() => setDeleteModalIsActive(true)}
            >
              Видалити карту
            </button>
          )}
        </div>
      </div>

      {deleteModalIsActive && (
        <DeleteItemConfirm
          setIsActive={setDeleteModalIsActive}
          data={cards}
          setData={setCards}
          dataType="cards"
          setParentModalIsActive={setModalIsActive}
          id={card.id}
        />
      )}
    </>
  );
};
