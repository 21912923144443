import { useEffect, useState } from "react";
import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Cards } from "./pages/cards/Cards.jsx";
import { NovaPoshta } from "./pages/NovaPoshta/NovaPoshta.jsx";
import { Statistic } from "./pages/cards/Statistic/Statistic.jsx";
import { Predictions } from "./pages/Predictions/Predictions.jsx";
import { mainContext } from "./utils/context";
import { loginPath } from "./constants";
import { Login } from "./pages/Login/Login";
import { HomePage } from "./pages/HomePage/HomePage";
import { getUserFromBX24 } from "./utils/getUserFromBx24";
import { useDispatch } from "react-redux";
import { setUser } from "./reducers/userReducer";
import { ChakraProvider } from "@chakra-ui/react";

function App() {
  const [isBX24, setIsBX24] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const contextValues = { setUser, isAuthenticated, setIsAuthenticated };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initializeBxUser = async () => {
    const bx24UserData = await getUserFromBX24();

    const bx24User = bx24UserData.result;

    if (bx24User) {
      console.log(1);
      dispatch(setUser(bx24User));
      setIsBX24(true);
    }
  };

  useEffect(() => {
    document.title = "Vandal One";
    initializeBxUser().then();
  }, []);

  useEffect(() => {
    if (!isBX24) {
      if (isAuthenticated) {
        navigate("/");
      } else {
        navigate(loginPath);
      }
    } else {
      if (!isAuthenticated) {
        setIsAuthenticated(true);
        navigate("/");
      }
    }
  }, [isAuthenticated, isBX24]);

  return (
    <mainContext.Provider value={contextValues}>
      <ChakraProvider>
        <div className="app">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path={loginPath} element={<Login />} />
            <Route path="/novaposhtaAPP" element={<NovaPoshta />} />
            <Route path="/cardsAPP" element={<Cards />} />
            <Route path="/predictionsAPP" element={<Predictions />} />
            <Route path="/statistic" element={<Statistic />} />
          </Routes>
        </div>
      </ChakraProvider>
    </mainContext.Provider>
  );
}

export default App;
