import "./PageHeader.sass";
import { useState, useEffect } from "react";
import React from "react";
import { NavLink } from 'react-router-dom';
import { AddEditCardModal } from "../modals/CardModal";

const PageHeader = ({ title, cards, setCards }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [cardModalIsActive, setCardModalIsActive] = useState(false);
 

  const  windowWidth  = 1200


  useEffect(() => {
    if (modalIsOpen || cardModalIsActive) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [modalIsOpen, cardModalIsActive]);



  const handleAddCard = () => {
    setCardModalIsActive(true);
  };

    return (
      <h2 className="page_header">
        {cardModalIsActive && (
          <AddEditCardModal
            type="add"
            card={{}}
            setModalIsActive={setCardModalIsActive}
            cards={cards}
            setCards={setCards}
          />
        )}
        <p>{title}</p>
        {windowWidth < 700
          ? null
          : 
        title == "Карточки" && (
          <div className="btns">
            <button onClick={handleAddCard} className="addCard header-btn">
              Нова карта
            </button>
            <NavLink to="/statistic">
            <button onClick={handleAddCard} className="statistic header-btn">
              Статистика
            </button>
            </NavLink>
          </div>
        )}

        {/*{modalIsOpen && <Modal setModalIsOpen={setModalIsOpen} />}*/}
      </h2>
    );
};

export default PageHeader;
