import './button.sass'
import React from "react";

const Button = (props) => {
    return (
        <button onClick={props.handler} className="btn">
            {props.text}
        </button>
    )
}

export default Button;