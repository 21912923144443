export const centerBlockStyle = {
  width: "100vw",
  height: "100vh",
  background: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const homePageStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  alignItems: "center",
};
