import { useDispatch } from "react-redux";
import "./modal.sass";
import { deleteCard } from "../../actions/cards";
import { deletePrediction } from "../../actions/prediction";
import React from "react";

export const DeleteItemConfirm = (props) => {
  const { setIsActive, data, setData, dataType, setParentModalIsActive, id } =
    props;
  const dispatch = useDispatch();

  const onBackgroundClick = (e) => {
    if (e.target.className === "back") {
      setIsActive(false);
    }
    return;
  };

  const setFilteredData = () => {
    setData(data.filter((item) => item.id !== id));
  };

  const handleDeleteItem = () => {
    if (dataType === "cards") {
      dispatch(deleteCard(id, setIsActive)).then(setFilteredData());
    } else if (dataType === "predictions") {
      dispatch(deletePrediction(id)).then(setFilteredData());
    }
    setIsActive(false);
    setParentModalIsActive(false);
  };

  return (
    <div className="back" onClick={(e) => onBackgroundClick(e)}>
      <div className="modal">
        <div className="modal__header">
          <h3>
            {dataType === "cards"
              ? "Видалити карту?"
              : "Видалити передбачення?"}
          </h3>
        </div>
        <div className="btns">
          <button className="btn deleteBtn" onClick={() => handleDeleteItem()}>
            Видалити
          </button>
          <button className="btn cancelBtn" onClick={() => setIsActive(false)}>
            Відхилити
          </button>
        </div>
      </div>
    </div>
  );
};
