import "./sortHeader.sass";
import React from "react";


const SortHeader = ({
  titles,
  setActiveSort = () => {},
  activeSort = "",
  setStatus,
  status,
}) => {
  return (
    <div
      className='service__sort'
      style={{ gridTemplateColumns: `repeat(${titles.length}, 1fr) 20px` }}
    >
      {titles.map((title, i) => (
        <SortTitle
          key={title.value + "_" + i}
          setActiveSort={setActiveSort}
          activeSort={activeSort}
          title={title}
          status={status}
          setStatus={setStatus}
        />
      ))}
    </div>
  );
};

const SortTitle = ({ title, activeSort, setActiveSort, status, setStatus }) => {
  const handleClick = () => {
    if (title.name === "status") {
      setStatus(!status);
    }
    if (activeSort.sort !== title.name) {
      setActiveSort({ sort: title.name, desc: false });
    } else {
      setActiveSort({ sort: title.name, desc: !activeSort.desc });
    }
  };

  return (
    <button
      className={activeSort.sort === title.name ? "active" : null}
      onClick={handleClick}
    >
      <span>{title.value}</span>
      <div
        className='drop'
        style={
          activeSort.sort === title.name && activeSort.desc
            ? { transform: "rotate(180deg)" }
            : { transform: "rotate(0)" }
        }
      >
        <span></span>
        <svg
          width='12'
          height='8'
          viewBox='0 0 12 8'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M1.41 0.589844L6 5.1698L10.59 0.589844L12 1.99984L6 7.9998L0 1.99984L1.41 0.589844Z'
            fill='#333A4A'
          />
        </svg>
      </div>
    </button>
  );
};

export default SortHeader;
