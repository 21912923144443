import "./modal.sass";
import React, { useState } from "react";
import Button from "../../components/button/button.jsx";
import {
  deleteCounterparty,
  resetQuarterlyCirculation,
  updateCounterparty,
} from "../../actions/counterparties.js";
import { useDispatch } from "react-redux";

export const CounterpartModal = (props) => {
  const { setIsActive, editingCounterpart, setCounterparties, counterparties } =
    props;
  const dispatch = useDispatch();

  const [status, setStatus] = useState(editingCounterpart.status);
  const [isTrusted, setIsTrusted] = useState(editingCounterpart.poa);
  const [isFop, setIsFop] = useState(editingCounterpart.fop);

  const closeModal = () => {
    setIsActive(false);
  };

  const onBackgroundClick = (e) => {
    if (e.target.className === "back") {
      closeModal();
    }
  };

  const handleUpdateItem = () => {
    dispatch(
      updateCounterparty(
        editingCounterpart.id,
        status,
        isTrusted,
        setIsActive,
        props.setCounterparties,
        props.counterparties,
        editingCounterpart.account,
        isFop
      )
    );
  };

  const handleDeleteItem = () => {
    dispatch(
      deleteCounterparty(
        editingCounterpart.id,
        setIsActive,
        props.setCounterparties,
        props.counterparties
      )
    );
  };

  const handleResetQuarterlyCirculation = () => {
    dispatch(
      resetQuarterlyCirculation(
        editingCounterpart.id,
        setCounterparties,
        counterparties
      )
    ).then(() => closeModal());
  };

  return (
    <div className="back" onClick={onBackgroundClick}>
      <div className="modal">
        <div className="modal__header">
          <h3>Редагування контрагента</h3>
          <button className="close" onClick={closeModal}>
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 7.70857L16.7914 6.5L12 11.2914L7.20857 6.5L6 7.70857L10.7914 12.5L6 17.2914L7.20857 18.5L12 13.7086L16.7914 18.5L18 17.2914L13.2086 12.5L18 7.70857Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
        <div className="input_wrapper">
          <p>Статус</p>
          <select value={status} onChange={(e) => setStatus(e.target.value)}>
            <option value="В черзі">В черзі</option>
            <option value="В роботі">В роботі</option>
            <option value="Використаний">Використаний</option>
            <option value="Новий">Новий</option>
            <option value="Використаний для ручного">
              Використаний для ручного
            </option>
            <option value="Черга для ручного">Черга для ручного</option>
          </select>
        </div>
        <div className="block__row">
          <div
            className={
              isTrusted ? "checkbox-wrapper checked" : "checkbox-wrapper"
            }
          >
            <button
              onClick={() => setIsTrusted(!isTrusted)}
              className="switch"
              htmlFor="checkbox"
            >
              <div className="slider round"></div>
            </button>
            <div className="input__name">
              {isTrusted ? "Довірена" : "Недовірена"}
            </div>
          </div>
        </div>
        <div className="block__row">
          <span style={{ padding: "5px" }}>ФОП?</span>
          <div
            className={isFop ? "checkbox-wrapper checked" : "checkbox-wrapper"}
          >
            <button
              onClick={() => setIsFop(!isFop)}
              className="switch"
              htmlFor="checkbox"
            >
              <div className="slider round"></div>
            </button>
            <div className="input__name">{isFop ? "Так" : "Ні"}</div>
          </div>
        </div>
        <div className="button">
          <Button
            text="Обнулити квартальний обіг"
            handler={handleResetQuarterlyCirculation}
          />
        </div>

        <div className="button">
          <Button text="Зберегти" handler={handleUpdateItem} />
        </div>
        <button className="delete" onClick={handleDeleteItem}>
          Видалити контрагента
        </button>
      </div>
    </div>
  );
};
